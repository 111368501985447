import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ReactNode } from 'react';

interface IList {
	children: ReactNode;
	className?: string;
	debugProps?: IDebugProps;
}

interface IListItem {
	children: ReactNode;
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	debugProps?: IDebugProps;
}

export const ListItem = (props: IListItem) => {
	const { children, className, onClick, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	return (
		<li
			className={`${className || ''}`}
			onClick={onClick}
			style={onClick ? { cursor: 'pointer' } : {}}
			{...generateDataAttrs(dataAttrs)}>
			{children}
		</li>
	);
};

export const List = (props: IList) => {
	const { children, className, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	return (
		<ul className={`${className || ''}`} {...generateDataAttrs(dataAttrs)}>
			{children}
		</ul>
	);
};
