import { Tooltip } from '../../../stores/tooltips.store';
import tooltipStyles from '../tooltip/tooltip.module.scss';
import { useHover } from '@monorepo/tools/src/lib/hooks/utils/use-hover';
import { useDebounce } from '@monorepo/tools/src/lib/hooks/utils/use-debounce';
import { TooltipThemes } from '../tooltip/tooltip';
import { useEffect, useRef, useState } from 'react';

interface IIndependentTooltip {
	tooltip: Tooltip;
}

const themeStyles = {
	[TooltipThemes.Dark]: tooltipStyles.dark,
	[TooltipThemes.Light]: tooltipStyles.light,
};

export const IndependentTooltip = (props: IIndependentTooltip) => {
	const { tooltip } = props;
	const { hoverRef, delay, theme, content, isPositionFixed, is, direction, forceHover, isHoveredOnTooltip, maxHeight } = tooltip;
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [position, setPosition] = useState({ top: 0, left: 0 });
	const [directionClass] = useState<string>(direction ? tooltipStyles[direction] : '');

	const isHover = useHover(hoverRef);
	const isTooltipHovered = useHover(tooltipRef);

	const getElementPosition = (element: HTMLElement) => {
		const rect = element.getBoundingClientRect();
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		const elementLeft = rect.left + scrollLeft;
		const elementTop = rect.top + scrollTop;
		const elementWidth = rect.width;

		return { left: elementLeft, top: elementTop, width: elementWidth };
	};

	useEffect(() => {
		if (hoverRef.current !== null && tooltipRef.current !== null) {
			const { top, left, width } = getElementPosition(hoverRef.current);
			setPosition({ top, left: left + width / 2 });
		}
	}, [isHover]);

	const debouncedIsHover = useDebounce((isHoveredOnTooltip && isTooltipHovered) || forceHover || isHover, delay);

	if (!is) {
		return null;
	}

	return (
		<div
			style={{ top: position.top, left: position.left, position: isPositionFixed ? 'fixed' : 'absolute', zIndex: 9 }}
			ref={tooltipRef}>
			<span className={`${tooltipStyles.wrapper}`}>
				<div
					style={{ maxHeight: maxHeight + 'px', overflow: maxHeight ? 'auto' : '' }}
					className={`${tooltipStyles.tooltip} ${debouncedIsHover ? tooltipStyles.active : ''} ${
						themeStyles[theme]
					} ${directionClass}`}>
					{content}
				</div>
			</span>
		</div>
	);
};
