import { Fragment, ReactNode } from 'react';
import { useGuard, validateGuard } from '../../hooks/tools/use-guard';

export interface IGuard {
	children: ReactNode; // TODO - change to ReactNode
	validate: validateGuard | validateGuard[];
}

export const Guard = (props: IGuard) => {
	const { children, validate } = props;

	const isPermitted = useGuard({ validate });
	if (!isPermitted) {
		return null;
	}

	return <Fragment>{children}</Fragment>;
};
