interface IKickAnnouncements {
	message?: string;
}

export const KickAnnouncements = (props: IKickAnnouncements) => {
	const {
		message = "Please check your login credentials and make sure that you have the necessary permissions to access the resource you're trying to access.",
	} = props;

	return <div>{message}</div>;
};
