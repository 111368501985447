import { Fragment, ReactNode, useRef } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { IAnnouncementInjectProps } from '../../announcement/announcement';
import styles from './action-announcement.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

interface IActionAnnouncement extends IAnnouncementInjectProps {
	onClose?: () => void;
	type?: AnnouncementTypes;
	children: ReactNode;
}

export enum AnnouncementTypes {
	Warning = 'warning',
	Info = 'info',
	Error = 'error',
	None = 'none',
}

export const ActionAnnouncement = (props: Partial<IActionAnnouncement>) => {
	const { children, onClose, closeAnnouncement, type } = props;
	const contentRef = useRef<HTMLDivElement>(null);

	const onCloseClick = () => {
		contentRef.current?.classList.add(styles.pushOut);

		if (onClose) {
			setTimeout(() => {
				onClose();
			}, 300);
		}

		if (closeAnnouncement) {
			setTimeout(() => {
				closeAnnouncement();
			}, 300);
		}
	};

	const announcementType = type || AnnouncementTypes.None;

	return (
		<Fragment>
			<div className={`${styles.content} ${styles[announcementType]} `} ref={contentRef}>
				<div className={styles.childrenWrapper}>{children}</div>
				<div className={styles.closeIconWrapper}>
					<Icon
						onClick={onCloseClick}
						className={styles.closeIcon}
						isMFP={true}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'action-announcement-close')] }}>
						x-close
					</Icon>
				</div>
			</div>
		</Fragment>
	);
};
