export enum Devices {
	Mobile = 'Mobile',
	// Tablet = 'Tablet',
	Desktop = 'Desktop',
}

export const devicesIconMapping: { [key: string]: string } = {
	Mobile: 'phone-android',
	Desktop: 'monitor-01',
};
