import { observer } from 'mobx-react';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAnnouncementItem } from '../../../stores/announcements.store';
import { Announcement } from '../announcement/announcement';

export const AnnouncementsContainer = observer(() => {
	const { announcementsStore } = useStores<IControlledStore>();
	const announcements = announcementsStore.getAnnouncements();

	return (
		<div>
			{announcements.map((announcement: IAnnouncementItem) => {
				const { children, id } = announcement;

				return (
					<Announcement key={id} {...announcement}>
						{children}
					</Announcement>
				);
			})}
		</div>
	);
});
