import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

/**
 * Will fetch list of models
 * @param listStore
 * @param options
 * @returns
 */
// TODO - DELETE
export function useList<T>(listStore: BaseListStore<T>, options?: IRequestOptions, hookOptions: BetweenPagesStateProps = {}) {
	const location = useLocation();
	const didMount = useDidMount();

	const isBlockRequest = hookOptions.isBlockRequest ?? false;

	useEffect(() => {
		const params = queryParamsToSearchParams(options?.queryParams);

		if (isBlockRequest || !didMount) {
			return;
		}

		listStore.reset();
		listStore.fetch(params);

		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				listStore.abort();
			}
		};
	}, [location, didMount]);

	return { error: listStore.getError(), isLoading: listStore.getIsLoading() };
}
