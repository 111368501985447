import { Fragment, ReactNode } from 'react';
import { useGuard, validateGuard } from '../../hooks/tools/use-guard';
import routes from '../../routes/index.routes';
import { Navigate, useLocation } from 'react-router-dom';

export interface IGuardRoute {
	children?: ReactNode; // TODO - change to ReactNode
	validate: validateGuard | validateGuard[];
}

export const GuardRoute = (props: IGuardRoute) => {
	const { children, validate } = props;
	const isPermitted = useGuard({ validate });
	const location = useLocation();

	if (!isPermitted) {
		return <Navigate to={routes.general.home()} state={{ from: location }} replace />;
	}

	return <Fragment>{children}</Fragment>;
};
