import { action, makeObservable, observable } from 'mobx';

export interface IAnnouncementAdd {
	children: JSX.Element;
}

export interface IAnnouncementItem {
	children: JSX.Element;
	id: number;
}

interface IAnnouncements {
	announcements: IAnnouncementAdd[];
}

export class AnnouncementsStore implements IAnnouncements {
	announcements: IAnnouncementItem[] = [];
	id = 1;
	type = '';

	constructor() {
		makeObservable(this, {
			announcements: observable,
			setAnnouncements: action,
			removeAnnouncement: action,
		});
	}

	getAnnouncements(): IAnnouncementItem[] {
		return this.announcements;
	}

	addAnnouncement(announcement: IAnnouncementAdd) {
		if (!announcement) {
			throw new Error('No announcement added to addAnnouncement function');
		}
		// Render announcements in the next render cycle
		setTimeout(() => {
			const announcements = [
				...this.announcements,
				{
					id: this.id++,
					...announcement,
				},
			];
			this.setAnnouncements(announcements);
		});
	}

	setAnnouncements(announcements: IAnnouncementItem[]) {
		this.announcements = announcements;
	}

	removeAnnouncement(id: number) {
		this.announcements = this.announcements.filter(announcement => announcement.id !== id);
	}

	getId(): number {
		return this.id;
	}
}
