import { PerformanceMetricsSymbols } from '@monorepo/tools/src/lib/enums/performance-labels';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs, DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { memo, useEffect, useState } from 'react';
import styles from './chart-tooltip.module.scss';

interface ITooltipDataItem {
	date: string;
	label: string;
	value: string;
	color: string;
	entityName?: string;
}

export interface ITooltipData {
	opacity: number;
	left: number;
	dataPoints: ITooltipDataItem[];
	top?: number;
	debugProps?: IDebugProps;
}

interface IChartTooltip {
	data: ITooltipData; // TODO
	chartWidth?: number;
	isDonutTooltip?: boolean;
	debugProps?: IDebugProps;
}

const TOOLTIP_WIDTH = 220;
const toFixedWithoutZeros = (num: number, precision: number) => `${Number.parseFloat(num.toFixed(precision))}`;

const _ChartTooltip = (props: IChartTooltip) => {
	const { data, chartWidth, isDonutTooltip } = props;
	const { top, left, opacity, dataPoints } = data;

	const [width, setChartWidth] = useState<number | undefined>(chartWidth);

	useEffect(() => {
		if (chartWidth) {
			setChartWidth(chartWidth);
		}
	}, [chartWidth]);

	let finalLeft = left;
	const finalTop = top;
	const tooltipFromRight = TOOLTIP_WIDTH + left;
	if (width && tooltipFromRight > width) {
		finalLeft = left - TOOLTIP_WIDTH;
	}

	if (opacity === 0 || dataPoints.length === 0) {
		return null;
	}

	const currentDate = dataPoints[0]?.date;

	return (
		<div
			{...generateDataAttrs([new DataAttribute('id', 'chart_tooltip')])}
			id={'chartTooltip'}
			className={`${styles.tooltip} ${isDonutTooltip ? styles.donutTooltip : ''}`}
			style={{ bottom: '100%', left: finalLeft, opacity, top: finalTop }}>
			<div className={styles.date}>{currentDate}</div>
			{dataPoints.map((item: ITooltipDataItem) => {
				const { color, label, value, entityName } = item;
				let parsedValue: number | string = value;
				const symbol = PerformanceMetricsSymbols.get(label as any);
				if (symbol === '%') {
					parsedValue = toFixedWithoutZeros(Number.parseFloat(value) * 100, 2);
				}

				return (
					<div key={`${label}${value}${color}`} className={styles.content}>
						<div className={styles.lineLabel}>
							<div className={styles.point} style={{ backgroundColor: color }}></div>
							<div className={styles.label}>{entityName ? entityName : label}</div>
						</div>
						{symbol ? (
							<div className={styles.sum}>
								<div className={styles.value}>{symbol === '%' ? `${parsedValue}${symbol}` : `${symbol}${parsedValue}`}</div>
							</div>
						) : (
							<div className={styles.value}>{value}</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export const ChartTooltip = memo(_ChartTooltip);
